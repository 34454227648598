.radio-zone {
  border-radius: 8px;
  border: 1px solid var(--color-neutral-300);
  background: rgba(142, 149, 162, 0.1);
}

.radio-zone.disabled {
  opacity: 0.3;
}

.radio-zone:not(.checked):hover {
  border-color: var(--color-neutral-400);
  background: rgba(142, 149, 162, 0.1);
}

.radio-zone:not(.checked):active {
  border-color: var(--color-neutral-500);
  background: rgba(107, 114, 128, 0.15);
}

.checked {
  border: 1px solid var(--color-primary-400);
  background: rgba(69, 57, 242, 0.1);
}

.radio-label {
  position: relative;
  background-color: transparent;
  border: 2px solid var(--color-neutral-300);
}

.checked .radio-label {
  border: 2px solid var(--color-primary-400);
}

.checked .radio-label::after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--color-primary-400);
}
