@import "Configs/Theme/constants.scss";

.root {
	background-color: white;
    padding: 100px 151px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

	@media (max-width: $screen-l) {
		padding: 100px 32px;
	}

	@media (max-width: $screen-s){
		padding: 40px 16px;
	}

    .button-view-more {
        width: 223px;
        height: 64px;
        color: #5f34bc;
    }
}
