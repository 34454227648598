@import "Configs/Theme/constants.scss";

.root {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 48px;

	.content {
		display: flex;
		justify-content: center;
		align-items: flex-start;;
		flex-wrap: wrap;
		gap: 40px;
		max-width: 1920px;
		padding-bottom: 48px;

		.wrapper {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 40px;
			max-width: 500px;

			@media (max-width: $screen-l) {
				padding: 0;
				max-width: 40vw;
			}
			

			.icon {
				object-fit: contain;
				width: 60.016px;
				height: 60px;
			}

			.title {
				color: var(--Light-Violet, #1a1324);
				font-family: Elephant;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px; /* 133.333% */
				letter-spacing: -0.48px;
			}

			.description {
				color: var(--Light-Violet, #1a1324);
				font-feature-settings: "salt" on;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px; /* 228.571% */
			}
		}
	}
}
