.items-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.item {
		display: flex;
		flex-direction: column;
		border-radius: 16px;
		background: var(--Plum, #1a1324);
		padding: 20px;
		width: 100%;
		cursor: pointer;
		user-select: none;

		&.open {
			.item-header {
				.question-and-icon {
					.icon-container {
						animation: rotateUp 0.3s ease-out forwards;
						background-color: transparent;
					}
				}
			}
		}
		.item-header {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: baseline;

			.question-and-icon {
				display: flex;
				justify-content: space-between;
				width: 100%;
				align-items: center;

				.question {
					display: flex;
					color: var(--White, #f9fafb);
					font-family: Elephant;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
					align-items: center;
				}

				.icon-container {
					display: flex;
					align-items: center;
					justify-content: center;
					animation: rotateDown 0.3s ease-out forwards;

					.icon {
						color: var(--color-neutral-900);
						width: 20px;
					}
				}

				@keyframes rotateDown {
					0% {
						transform: rotate(180deg);
					}

					100% {
						transform: rotate(0deg);
					}
				}

				@keyframes rotateUp {
					0% {
						transform: rotate(0deg);
					}

					100% {
						transform: rotate(180deg);
					}
				}
			}
		}

		.item-response {
			animation: closeResponse 0.3s ease-out forwards;

			&.open {
				animation: openResponse 0.3s ease-out forwards;
			}

			p,
			span {
				pointer-events: none;
				margin: 10px 0px 0px;
				color: var(--Light-Gray, #d1d5db);
				font-feature-settings: "salt" on;

				/* Copy */
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px; /* 228.571% */
			}
		}

		@keyframes closeResponse {
			0% {
				opacity: 1;
				max-height: 2000px;
			}

			100% {
				opacity: 0;
				max-height: 0px;
			}
		}

		@keyframes openResponse {
			0% {
				opacity: 0;
				max-height: 0px;
			}

			100% {
				opacity: 1;
				max-height: 2000px;
			}
		}
	}
}
