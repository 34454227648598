.search-preview {
  background-color: var(--color-neutral-100);
}

.search-preview-item:hover {
  background-color: var(--color-neutral-200);
  border-radius: 8px;
}

.search-preview-item:active {
  background-color: var(--color-neutral-300);
  border-radius: 8px;
}
