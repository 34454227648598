@import "Configs/Theme/constants.scss";

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.root {
	// background-size: contain;
	background-position: top;
	// background-repeat: no-repeat;
	position: relative;
	overflow: hidden;
	width: 100%;

	.content {
		height: 1080px;
		display: flex;
		flex-direction: row;

		@media (max-width: $screen-m) {
			flex-direction: column;
			height: 100%;
		}

		.physical-section {
			position: relative;
			height: 100%; /* or the height of the area the background should cover */
			overflow: hidden;

			@media (max-width: $screen-m) {
				display: flex;

				padding: 64px 32px 256px 32px;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 48px;
			}

			.physical-section-title-invisible {
				position: absolute;
				z-index: 99;
				opacity: 0;
				top: 508px;
				left: 140px;

				@media (max-width: $screen-m) {
					display: none;
				}
			}

			.physical-section-title {
				position: absolute;
				font-style: normal;
				font-weight: 400;
				line-height: 64px; /* 88.889% */
				z-index: 3;
				font-size: 72px;
				text-wrap: nowrap;
				transform-origin: center;

				@media (max-width: $screen-m) {
					position: static;
					line-height: 96px;
					transform: translateX(-100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
				}

				@media (max-width: $screen-s) {
					font-size: 48px;
				}
			}

			.physical-section-cta {
				position: absolute;
				z-index: 3;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px; /* 228.571% */
				text-wrap: nowrap;
				transform-origin: center;

				@media (max-width: $screen-m) {
					position: static;
					line-height: 96px;
					transform: translateX(-100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
				}

				@media (max-width: $screen-s) {
					display: none;
				}
			}

			.physical-section-subtitle {
				position: absolute;
				z-index: 3;
				max-width: clamp(300px, 50%, 771px);;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px;
				top: 447px;

				@media (max-width: $screen-m) {
					position: static;
					line-height: 32px;
					transform: translateX(-100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
					max-width: unset;
				}
			}

			.whistlepig-physical-bottle-img {
				position: absolute;
				object-fit: contain;
				width: 858.099px;
				height: 858.099px;
				z-index: 1;

				@media (max-width: $screen-m) {
					width: 305px;
					height: 539px;
					left: calc(50vw - 152.5px);
					bottom: -250px;
					transform: translateX(-100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
				}
			}

			.visible-element {
				transform: translateX(0); /* Move element into view */
				opacity: 1; /* Fully opaque */
			}
		}

		.digital-section {
			position: relative;
			height: 100%; /* or the height of the area the background should cover */
			overflow: hidden;

			@media (max-width: $screen-m) {
				display: flex;
				padding: 224px 32px 64px 32px;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				gap: 48px;
			}

			.digital-section-title-invisible {
				position: absolute;
				z-index: 99;
				opacity: 0;
				top: 508px;
				right: 610px;
				@media (max-width: $screen-m) {
					display: none;
				}
			}

			.digital-section-title {
				position: absolute;
				z-index: 3;
				font-style: normal;
				font-weight: 400;
				line-height: 64px;
				font-size: 72px;
				text-wrap: nowrap;
				transform-origin: center;

				@media (max-width: $screen-m) {
					position: static;
					line-height: 96px;
					transform: translateX(100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
				}

				@media (max-width: $screen-s) {
					font-size: 48px;
				}
			}

			.digital-section-cta {
				position: absolute;
				z-index: 3;
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px; /* 228.571% */
				text-wrap: nowrap;
				transform-origin: center;

				@media (max-width: $screen-m) {
					position: static;
					line-height: 96px;
					transform: translateX(100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
				}

				@media (max-width: $screen-s) {
					display: none;
				}
			}

			.digital-section-subtitle {
				position: absolute;
				z-index: 3;
				max-width: clamp(300px, 50%, 771px);;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 32px; /* 228.571% */
				top: 447px;

				@media (max-width: $screen-m) {
					
					position: static;
					line-height: 32px;
					transform: translateX(100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
					max-width: unset;
				}
			}

			.whistlepig-digital-bottle-img {
				position: absolute;
				object-fit: contain;

				width: 858.099px;
				height: 858.099px;
				z-index: 1;

				@media (max-width: $screen-m) {
					width: 305px;
					height: 539px;
					left: calc(50vw - 149.5px);
					top: -290px;
					transform: translateX(100vh); /* Start outside of the page */
					opacity: 0; /* Start with the element fully transparent */
					transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition for both transform and opacity */
				}
			}

			.visible-element {
				transform: translateX(0); /* Move element into view */
				opacity: 1; /* Fully opaque */
			}
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover; /* Ensures video covers the div completely */
			z-index: -1; /* Ensures video stays in the background */
		}
	}
}
