.root-button {
  position: relative;
  border: 0;
  border-radius: 8px;
  min-width: 128px;
  background: transparent;
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-medium);
  transition: background 150ms ease-out;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  outline: none;
}

.root-button:disabled {
  pointer-events: none;
  opacity: 0.3;
}

.root-button.large {
  height: 48px;
  font-size: 18px;
  padding: 12px 24px;
}

.root-button.medium {
  height: 40px;
  font-size: 16px;
  padding: 8px 16px;
}

.root-button.small {
  height: 36px;
  font-size: 14px;
  padding: 6px 16px;
}

.root-button.xsmall {
  height: 32px;
  font-size: 14px;
  padding: 4px 16px;
}

.root-button.fullwidth {
  width: 100%;
}

/* CONTAINED BUTTONS */
/* PRIMARY */
.root-button.contained.primary {
  background: linear-gradient(90deg, #5f34bc 0%, #95119f 100%);
  color: var(--color-neutral-900);
}
.root-button.contained.primary:not(.is-loading) path {
  stroke: var(--color-neutral-900);
}

.root-button.contained.primary:hover {
  background: linear-gradient(90deg, #7b53cf 0%, #c216cf 100%);
}

.root-button.contained.primary:active {
  background: linear-gradient(90deg, #9a7bda 0%, #dc30e9 100%);
}

/* NEUTRAL */
.root-button.contained.neutral {
  border: 1px solid var(--color-neutral-300);
  background: var(--color-neutral-300);
  color: var(--color-neutral-600);
}
.root-button.contained.neutral:not(.is-loading) path {
  stroke: var(--color-neutral-600);
}

.root-button.contained.neutral:hover {
  background: var(--color-neutral-200);
}

.root-button.contained.neutral:active {
  background: var(--color-neutral-300);
}
/* DESTRUCTIVE */
.root-button.contained.destructive {
  background: var(--color-error-600);
  color: var(--color-neutral-900);
}
.root-button.contained.destructive:not(.is-loading) path {
  stroke: var(--color-neutral-900);
}

.root-button.contained.destructive:hover {
  background: var(--color-error-500);
}

.root-button.contained.destructive:active {
  background: var(--color-error-400);
}

/* OUTLINED BUTTONS */
/* PRIMARY */
.root-button.outlined.primary {
  /* Text gradient */
  background-image: linear-gradient(90deg, #9a7bda 0%, #dc30e9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  /* Border gradient */
  border: 1px solid #95119f;
}
.root-button.outlined.primary:not(.is-loading) path {
  stroke: #dc30e9;
}

.root-button.outlined.primary:hover {
  border: 1px solid #c216cf;
}

.root-button.outlined.primary:active {
  border: 1px solid #dc30e9;
}

/* NEUTRAL */
.root-button.outlined.neutral {
  border: 1px solid var(--color-neutral-300);
  background: var(--color-neutral-100);
  color: var(--color-neutral-600);
}
.root-button.outlined.neutral:not(.is-loading) path {
  stroke: var(--color-neutral-600);
}

.root-button.outlined.neutral:hover {
  background: var(--color-neutral-200);
}

.root-button.outlined.neutral:active {
  background: var(--color-neutral-300);
}

/* DESTRUCTIVE */
.root-button.outlined.destructive {
  border: 1px solid var(--color-error-500);
  color: var(--color-error-500);
}
.root-button.outlined.destructive:not(.is-loading) path {
  stroke: var(--color-error-500);
}

.root-button.outlined.destructive:hover {
  border: 1px solid var(--color-error-400);
  color: var(--color-error-400);
}

.root-button.outlined.destructive:active {
  border: 1px solid var(--color-error-300);
  color: var(--color-error-300);
}

/* TEXT BUTTONS */
/* PRIMARY */
.root-button.text.primary {
  /* Text gradient */
  background-image: linear-gradient(90deg, #7b53cf 0%, #c216cf 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.root-button.text.primary:not(.is-loading) path {
  stroke: #c216cf;
}

.root-button.text.primary:hover {
  background-image: linear-gradient(90deg, #9a7bda 0%, #dc30e9 100%);
}
.root-button.text.primary:hover:not(.is-loading) path {
  stroke: #dc30e9;
}

.root-button.text.primary:active {
  background-image: linear-gradient(90deg, #7b53cf 0%, #c216cf 100%);
}
.root-button.text.primary:active:not(.is-loading) path {
  stroke: #c216cf;
}

/* NEUTRAL */
.root-button.text.neutral {
  color: var(--color-neutral-600);
}
.root-button.text.neutral:not(.is-loading) path {
  stroke: var(--color-neutral-600);
}

.root-button.text.neutral:hover {
  color: var(--color-neutral-200);
}
.root-button.text.neutral:hover:not(.is-loading) path {
  stroke: var(--color-neutral-200);
}

.root-button.text.neutral:active {
  color: var(--color-neutral-100);
}
.root-button.text.neutral:active:not(.is-loading) path {
  stroke: var(--color-neutral-100);
}

.root-button .loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 4px;
}

.root-button:not(.is-loading) svg {
  min-height: 16px;
  max-height: 16px;
  min-width: 16px;
  max-width: 16px;
}
