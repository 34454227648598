@import "Configs/Theme/constants.scss";

.root {
	min-height: 1080px;
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 100px 151px;

	@media (max-width: $screen-l) {
		padding: 100px 32px;
	}

	@media (max-width: $screen-s) {
		padding: 100px 16px;
	}
	.content {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		

		@media (max-width: $screen-l) {
			flex-direction: column-reverse;
			align-items: flex-start;
			gap: 40px;
		}

		.dropdown-container {
			position: relative;
			max-width: 674px;
			z-index: 2;
		}

		.container {
			position: relative;
			width: 100%;

			.question-mark-icon {
				@media (max-width: $screen-l) {
					object-fit: contain;
					position: absolute;
					width: 244.429px;
					height: 362.962px;
					left: 400px;
					bottom: -130px;
					z-index: 0;
				}

				@media (max-width: $screen-s) {
					object-fit: contain;
					position: absolute;
					width: 124.628px;
					height: 185.065px;
					left: 220px;
					top: 35px;
				}
			}

			.wrapper {
				position: absolute;
				display: flex;
				flex-direction: column;
				gap: 20px;
				left: 100px;
				top: 400px;
				max-width: 469px;

				@media (max-width: $screen-l) {
					position: static;
				}

				.title {
					color: var(--White, #f9fafb);
					font-family: Elephant;
					font-size: 72px;
					font-style: normal;
					font-weight: 400;
					line-height: 64px; /* 88.889% */

					@media (max-width: $screen-s) {
						font-size: 48px;
					}
				}

				.description {
					color: var(--Light-Gray, #d1d5db);
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 32px; /* 228.571% */

					@media (max-width: $screen-s) {
						font-size: 14px;
					}
				}
			}
		}
	}
}
