@import "Configs/Theme/constants.scss";

.root {
	background-color: white;

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 48px;
		padding: 100px 151px 0 151px;

		@media (max-width: $screen-l) {
			padding: 100px 0;
		}

		@media (max-width: $screen-s) {
			padding: 100px 0;
		}

		.title {
			color: var(--Light-Violet, #1a1324);
			text-align: center;
			font-size: 72px;
			font-style: normal;
			font-weight: 400;
			line-height: 64px; /* 88.889% */

			@media (max-width: $screen-l) {
				font-size: 48px;
			}
		}

		.bottles-type-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 80px;
			flex-wrap: wrap;
			padding: 0 16px;

			@media (max-width: $screen-s) {
				gap: 4vw;
			}

			.bottle-type-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;
				cursor: pointer;

				.bottle-type-name {
					text-align: center;
					font-size: 24px;
					font-family: Elephant;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
					background: var(--Dark-Violet, rgba(20, 14, 29, 0.9));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;

					&.bottle-type-selected {
						background: var(--Violet-Gradient, linear-gradient(90deg, #5f34bc 0%, #95119f 100%));
						background-clip: text;
					}

					@media (max-width: $screen-s) {
						font-size: 18px;
					}
				}

				.bottle-type-icon {
					svg {
						width: 52.802px;
						height: 60px;

						@media (max-width: $screen-s) {
							width: 34.474px;
							height: 40px;
						}

						path {
							fill: #d1d5db;
						}
					}

					&.bottle-type-icon-selected {
						svg {
							path {
								fill: url(#paint0_linear_18215_27976);
							}
						}
					}
				}
			}
		}

		.bottles-container {
			width: 100%;
			overflow-x: auto;
			white-space: nowrap;
			padding: 0 151px;

			&.align-center {
				text-align: center;
			}

			@media (max-width: $screen-l) {
			}

			@media (max-width: $screen-l) {
				padding: 0 40px;
			}

			@media (max-width: $screen-s) {
				padding: 0 16px;
			}

			.sold-out-container {
				display: flex;
				justify-self: center;
				align-items: center;
				flex-direction: column;
				height: 300px;
				text-align: center;

				.sold-out {
					color: var(--Light-Violet, #1a1324);
					font-size: 36px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
					font-family: Elephant;
					margin: auto;
				}
			}

			.bottle-wrapper {
				display: inline-block;
				width: 316px;
				height: fit-content;
				padding: 0 0 40px 0;
				border-radius: 24px;
				border: 1px solid #f8f0ff;
				margin: 0 10px;

				@media (max-width: $screen-s) {
					width: 190px;
				}

				.image-container {
					padding: 10px;
					height: 316px;
					width: 316px;
					background-color: black;
					border-top-left-radius: 24px;
					border-top-right-radius: 24px;

					@media (max-width: $screen-s) {
						width: 190px;
						height: 190px;
					}

					.bottle-img {
						display: block;
						object-fit: contain;

						margin: auto;
					}
				}

				&:nth-last-child {
					margin-right: 0;
				}

				&:nth-child(1) {
					margin-left: 0;
				}

				.bottle-name {
					display: block;
					color: var(--Light-Violet, #1a1324);
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
					font-family: Elephant;
					margin: auto;
					margin-top: 16px;
					padding-left: 16px;
					padding-right: 16px;

					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;

					@media (max-width: $screen-s) {
						font-size: 14px;
						padding-left: 10px;
						padding-right: 10px;
					}
				}

				.bottle-price {
					margin-top: 16px;
					padding-left: 16px;
					padding-right: 16px;
					@media (max-width: $screen-s) {
						padding-left: 10px;
						padding-right: 10px;
					}
				}

				.bottle-description {
					color: var(--Light-Violet, #1a1324);
					text-align: center;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
					font-family: Poppins;
					margin: auto;
					margin-top: 8px;
				}

				.bottle-buy-button {
					width: 146px;
					height: 56px;
					color: #5f34bc;
					border-radius: 8px;
					border: 1px solid var(--Violet-Gradient, #5f34bc);
					box-shadow: 0px 8px 22.8px 0px rgba(97, 51, 188, 0.36);
					margin: auto;
					display: block;
					margin-top: 16px;
				}
				.link {
					margin-top: 16px;
					display: block;
				}
			}
		}
	}
}
