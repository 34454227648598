@import "Configs/Theme/constants.scss";

// Disable autofill styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: black !important; /* Force text color */
	-webkit-box-shadow: 0 0 0px 1000px white inset; /* Force background color */
}

input:-moz-ui-invalid {
	box-shadow: none !important;
}

.root {
	background-color: var(--color-generic-white);
	padding: 100px 151px;

	@media (max-width: $screen-l) {
		padding: 100px 32px;
	}

	@media (max-width: $screen-l) {
		padding: 100px 16px;
	}

	.content {
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 40px;
		max-width: 772px;

		.icon {
			object-fit: contain;

			@media (max-width: $screen-l) {
				width: 128px;
				height: 128px;
			}
		}

		.title {
			color: var(--Light-Violet, #1a1324);
			text-align: center;
			font-family: Elephant;
			font-size: 72px;
			font-style: normal;
			font-weight: 400;
			line-height: 64px; /* 88.889% */

			@media (max-width: $screen-s) {
				font-size: 48px;
			}
		}

		.description {
			color: var(--Plum, #1a1324);
			text-align: center;
			font-feature-settings: "salt" on;

			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px; /* 228.571% */
		}

		.form {
			display: flex;
			gap: 10px;

			@media (max-width: $screen-s) {
				flex-direction: column;
				width: 100%;
			}

			.email-input {
				border-color: var(--color-primary-100);
				color: var(--color-generic-black);
				flex: 1;
				width: 420px;
				@media (max-width: $screen-s) {
					width: unset;
				}

				&::placeholder {
					color: var(--color-generic-black);
				}
			}
		}

		.success {
			font-feature-settings: "salt" on;

			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 32px; /* 228.571% */
		}
	}
}
