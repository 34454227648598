@import "Configs/Theme/constants.scss";

.root {
	background-color: white;
	padding: 40px 32px;

	@media (max-width: $screen-s) {
		padding: 40px 0;
		
	}

	.content {
		display: flex;
		justify-content: center;
		align-items: center;

		.banner {
			background-position: 80% center;
			background-size: cover;
			border-radius: 24px;
			display: flex;
			flex-direction: column;
			max-width: 1640px;
			align-items: flex-start;
			gap: 20px;
			width: calc(100% - 160px);
			padding: 40px 80px;

			@media (max-width: $screen-l) {
				padding: 40px;
				width: 100%;
			}

			@media (max-width: $screen-s) {	
				border-radius: 0;
			}

			.banner-header {
				color: var(--White, #f9fafb);
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 20px; /* 142.857% */
				letter-spacing: 0.14px;
				text-transform: uppercase;
			}

			.banner-title {
				color: var(--White, #f9fafb);
				font-family: Elephant;
				font-size: 72px;
				font-style: normal;
				font-weight: 400;
				line-height: 64px; /* 88.889% */

				@media (max-width: $screen-l) {
					font-size: 48px;
				}
			}

			.banner-button {
				width: 151px;
				height: 48px;
				color: var(--color-generic-white, #f9fafb);
				border-radius: 8px;
				background: var(--Violet-Gradient, linear-gradient(90deg, #5f34bc 0%, #95119f 100%));
				box-shadow: 0px 8px 22.8px 0px rgba(97, 51, 188, 0.36);
				// padding: 24px 48px;
			}
		}

		.title {
			color: var(--Light-Violet, #1a1324);
			text-align: center;
			font-size: 72px;
			font-style: normal;
			font-weight: 400;
			line-height: 64px; /* 88.889% */
		}

		.bottles-type-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 80px;
			flex-wrap: wrap;

			.bottle-type-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 16px;

				.bottle-type-name {
					text-align: center;
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
					background: var(--Violet-Gradient, linear-gradient(90deg, #5f34bc 0%, #95119f 100%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				.bottle-type-icon {
					width: 52.802px;
					height: 60px;
				}
			}
		}

		.bottles-container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 20px;
			flex-wrap: wrap;

			.bottle-wrapper {
				display: flex;
				max-width: 316px;
				padding: 10px 0 40px 0;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 16px;
				border-radius: 24px;
				border: 1px solid #f8f0ff;
				flex: 468px 0 0;

				.bottle-img {
					object-fit: contain;
					width: 316px;
					height: 316px;
					flex-shrink: 0;
				}

				.bottle-name {
					color: var(--Light-Violet, #1a1324);
					text-align: center;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
				}

				.bottle-description {
					color: var(--Light-Violet, #1a1324);
					text-align: center;
					font-size: 12x;
					font-style: normal;
					font-weight: 400;
					line-height: 32px; /* 133.333% */
					letter-spacing: -0.48px;
				}

				.bottle-buy-button {
					width: 146px;
					height: 56px;
					color: #5f34bc;
					border-radius: 8px;
					border: 1px solid var(--Violet-Gradient, #5f34bc);
					box-shadow: 0px 8px 22.8px 0px rgba(97, 51, 188, 0.36);
				}
			}
		}

		.view-all-nfts-button {
			color: #5f34bc;
		}
	}
}
