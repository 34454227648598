/* REGION ACTIVE */
.active {
  background-color: var(--color-generic-white);
  border: 2px solid var(--color-neutral-900);
}
.active .check-icon {
  color: var(--color-generic-white);
}

.active.first ~ .after {
  background-color: var(--color-neutral-300);
}

.active.middle ~ .before {
  background-color: var(--color-neutral-900);
}

.active.middle ~ .after {
  background-color: var(--color-neutral-300);
}

.active.last ~ .before {
  background-color: var(--color-neutral-900);
}
/* END REGION ACTIVE */

/* REGION COMPLETED */
.completed {
  background-color: var(--color-neutral-900);
}

.completed .check-icon {
  color: var(--color-neutral-50);
}

.completed.first ~ .after {
  background-color: var(--color-neutral-900);
}

.completed.middle ~ .before {
  background-color: var(--color-neutral-900);
}

.completed.middle ~ .after {
  background-color: var(--color-neutral-900);
}

.completed.last ~ .before {
  background-color: var(--color-neutral-900);
}
/* END REGION COMPLETED */

/* REGION COMING SOON */
.comingSoon {
  background-color: var(--color-neutral-300);
}

.comingSoon .check-icon {
  color: var(--color-neutral-300);
}

.comingSoon.first ~ .after {
  background-color: var(--color-neutral-300);
}

.comingSoon.middle ~ .before {
  background-color: var(--color-neutral-300);
}

.comingSoon.middle ~ .after {
  background-color: var(--color-neutral-300);
}

.comingSoon.last ~ .before {
  background-color: var(--color-neutral-300);
}
/* END REGION COMING SOON */
