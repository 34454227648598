@import "Configs/Theme/constants.scss";

.root {
	height: 1080px;
	background-position: top;
	background-size: cover;

	@media (max-width: $screen-l) {
		height: 1366px;
	}

	@media (max-width: $screen-m) {
		height: 760px;
	}

	.content {
		position: relative;
		width: 100%; /* or the width of the area the background should cover */
		height: 100%; /* or the height of the area the background should cover */
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		@media (min-width: $screen-xl) {
			align-items: center;
		}

		.container {
			display: flex;
			flex-direction: column;

			gap: 20px;
			padding-right: 64px;
			padding-left: 64px;
			max-width: 1440px;
			// @media (max-width: $screen-l) {
			// 	margin-top: 200px;
			// };

			@media (max-width: $screen-m) {
				padding-right: 32px;
				padding-left: 32px;
			}

			@media (max-width: $screen-s) {
				padding-right: 16px;
				padding-left: 16px;
			}

			.whistlepig-bottle-blurred-img {
				position: absolute;
				object-fit: contain;
				width: 778.616px;
				height: 778.616px;
				transform: rotate(-14.008deg);
				z-index: 0;
				top: -500px;
				left: -200px;
				filter: blur(15px);

				@media (max-width: $screen-m) {
					left: -300px;
					transform: rotate(14.008deg);
				}
			}

			.whistlepig-bottle-img {
				position: absolute;
				object-fit: contain;
				width: 858.099px;
				height: 858.099px;
				position: absolute;
				right: -157.359px;
				top: -47.17px;
				transform: rotate(-14.008deg);
				z-index: 1;
				filter: blur(1px);

				@media (max-width: $screen-l) {
					right: -300px;
				}

				@media (max-width: $screen-m) {
					top: 0;

					display: none;
				}
			}

			.angels-envy-bottle-img {
				position: absolute;
				object-fit: contain;
				width: 858.099px;
				height: 858.099px;
				z-index: 1;
				bottom: -130px;
				left: -40px;

				@media (max-width: $screen-l) {
					width: 815px;
					height: 815px;
					left: -300px;
					bottom: -100px;
				}

				@media (max-width: $screen-m) {
					width: 388.154px;
					height: 388.154px;
					left: 100px;
					bottom: 0px;
				}
			}

			.caviar-img {
				position: absolute;
				object-fit: contain;
				width: 1168.115px;
				height: 1168.115px;
				transform: rotate(-14.008deg) scale(1.5);
				filter: blur(50px);

				z-index: 1;
				top: -200px;
				right: -380px;

				@media (max-width: $screen-l) {
					top: -70px;
					right: -450px;
				}

				@media (max-width: $screen-m) {
					top: -400px;
					right: -700px;
				}
			}

			.title-img {
				width: 1119px;
				object-fit: contain;
				align-self: start;
				z-index: 2;
			}

			.title {
				position: relative;
				color: var(--White, #f9fafb);
				font-family: Elephant;
				font-size: 250px;
				font-style: normal;
				font-weight: 400;
				line-height: 250px; /* 100% */
				text-shadow: 59px 53px 30px rgba(0, 0, 0, 0.25);

				@media (max-width: $screen-l) {
					font-size: 152px;
					line-height: 157.283px;
				}

				@media (max-width: $screen-m) {
					font-size: 79.982px;
					line-height: 79.982px;
				}
			}

			.title::before {
				position: absolute;
				content: "Welcome to the World of ";
				color: var(--Neutral-50, #f9fafb);
				font-family: Elephant;
				font-size: 36px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px; /* 88.889% */
				letter-spacing: -0.72px;
				top: 32px;
				left: 240px;

				@media (max-width: $screen-l) {
					font-size: 22.649px;
					top: 0px;
					left: 160px;
				}

				@media (max-width: $screen-m) {
					font-size: 11.517px;
					top: 0px;
					left: 80px;
				}
			}

			.text-container {
				position: relative;
				margin-top: 81px;
				max-width: 673px;
				right: -270px;
				align-self: flex-end;

				display: flex;
				flex-direction: column;
				gap: 40px;
				z-index: 2;

				@media (max-width: $screen-l) {
					right: 0;
					margin-left: 30vw;
					margin-top: 400px;
				}

				@media (max-width: $screen-m) {
					margin-left: 0;
					margin-top: 0;
					align-self: flex-start;
				}

				.subtitle {
					color: var(--White, #f9fafb);
					font-feature-settings: "salt" on;
					font-family: Poppins;
					font-size: 21px;
					font-style: normal;
					font-weight: 500;
					line-height: 48px; /* 228.571% */

					@media (max-width: $screen-m) {
						color: var(--White, #f9fafb);
						font-feature-settings: "salt" on;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; /* 171.429% */
					}
				}

				.get-started-button {
					height: 72px;
					width: 199px;
					padding: 24px 48px;
					border-radius: 8px;
					box-shadow: 0px 8px 22.8px 0px rgba(97, 51, 188, 0.36);
				}
			}
		}
	}

	.content video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover; /* Ensures video covers the div completely */
		z-index: -1; /* Ensures video stays in the background */
	}
}
