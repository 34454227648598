@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./MainSearchBar/index.scss";
@import "./MintCard/index.scss";
@import "./UserRegistrationBanner/index.scss";
@import "./MintSchedule/index.scss";
@import "./ClaimItem/index.scss";
@import "./RadioZone/index.scss";
@import "./Selector/index.scss";
@import "./Button/index.scss";

[theme-mode="dark"],
:root {
	--color-neutral-50: #faf9fb;
	--color-neutral-100: #f5f3f6;
	--color-neutral-200: #e9e5eb;
	--color-neutral-300: #d7d1db;
	--color-neutral-400: #a79caf;
	--color-neutral-500: #776b80;
	--color-neutral-600: #594b63;
	--color-neutral-700: #463751;
	--color-neutral-800: #2d1f37;
	--color-neutral-900: #120819;

	--color-primary-50: #f5f4fe;
	--color-primary-100: #edebfc;
	--color-primary-200: #dddafa;
	--color-primary-300: #c4bcf6;
	--color-primary-400: #a596ef;
	--color-primary-500: #886be7;
	--color-primary-600: #5f34bc;
	--color-primary-700: #552fa8;
	--color-primary-800: #47288a;
	--color-primary-900: #2b185d;

	--color-secondary-50: #f3e7fe;
	--color-secondary-100: #dfc5fd;
	--color-secondary-200: #ca9dfd;
	--color-secondary-300: #b371fe;
	--color-secondary-400: #a14bfc;
	--color-secondary-500: #8d17f9;
	--color-secondary-600: #810ef3;
	--color-secondary-700: #7100ea;
	--color-secondary-800: #6100e5;
	--color-secondary-900: #4800d7;

	--color-success-50: #f0fdf4;
	--color-success-100: #dcfce7;
	--color-success-200: #bbf7d0;
	--color-success-300: #86efac;
	--color-success-400: #4ade80;
	--color-success-500: #22c55e;
	--color-success-600: #16a34a;
	--color-success-700: #15803d;
	--color-success-800: #166534;
	--color-success-900: #14532d;

	--color-warning-50: #fffbeb;
	--color-warning-100: #fef3c7;
	--color-warning-200: #fde68a;
	--color-warning-300: #fcd34d;
	--color-warning-400: #fbbf24;
	--color-warning-500: #f59e0b;
	--color-warning-600: #d97706;
	--color-warning-700: #b45309;
	--color-warning-800: #92400e;
	--color-warning-900: #78350f;

	--color-error-50: #fef2f2;
	--color-error-100: #fee2e2;
	--color-error-200: #fecaca;
	--color-error-300: #fca5a5;
	--color-error-400: #f87171;
	--color-error-500: #ef4444;
	--color-error-600: #dc2626;
	--color-error-700: #b91c1c;
	--color-error-800: #991b1b;
	--color-error-900: #7f1d1d;

	--color-generic-white: #ffffff;
	--color-generic-black: #000000;

	--color-generic-background: var(--color-neutral-50);

	/* FONTS */
	--font-family-primary: "Elephant";
	--font-family-secondary: "inter";
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
	--font-weight-extrabold: 800;
	--color-primary: var(--color-primary-500);
	--color-typo-secondary: var(--color-secondary-900);

	/* PROGRESS BAR */
	--progress-bar-background: var(--color-neutral-100);

	/* LINEAR GRADIENTS */
	--linear-gradient: linear-gradient(90deg, #9a7bda 0%, #dc30e9 100%);

	/* MAIN SEARCHBAR  */
	--main-searchbar-border: "none";
	--main-searchbar-background: transparent;

	/* TAB SWITCHER */
	--tab-switcher-color-active: var(--color-neutral-900);

	/* BORDERS */
	--border: 1px solid var(--color-neutral-300);
	--main-searchbar-border-radius: 8px;

	/* BORDER RADIUS */
	--border-radius-select: 8px 0 0 8px;
	--border-radius-amount-input-field: 0 8px 8px 0;
	--border-radius-input: 8px;
	--border-radius-textarea: 8px;
	--border-radius-language-mode-switcher: 16px;

	/* SHADOWS */
	--shadow-main-specific: "none";
	--shadow-small: 0px 6px 12px 0px rgba(6, 4, 8, 0.11);
	--shadow-medium: 0px 9px 18px 0px rgba(6, 4, 8, 0.15);
	--shadow-light: 0px 10px 15px -3px rgba(6, 4, 8, 0.05);

	/* SELECTOR BAR */
	--selector-bar-selected-background-color: var(--color-primary-400);
	--selector-bar-selected-color: var(--color-neutral-900);

	/* BUTTONS */
	--border-button: none;
	--button-border-radius: 8px;
	/** CONTAINED BUTTONS **/
	--contained-button-color: var(--color-neutral-900);
	--contained-button-background: linear-gradient(90deg, #5f34bc 0%, #95119f 100%);
	--contained-button-background-hover: linear-gradient(90deg, #7b53cf 0%, #c216cf 100%);
	--contained-button-background-active: linear-gradient(90deg, #9a7bda 0%, #dc30e9 100%);
	--contained-button-background-disabled: linear-gradient(90deg, #5f34bc 0%, #95119f 100%);

	/** OUTLINED BUTTONS **/
	--outlined-button-color: linear-gradient(90deg, #5f34bc 0%, #95119f 100%);
	--outlined-button-border-color: linear-gradient(90deg, #5f34bc 0%, #95119f 100%);
	--outlined-button-background: var(--color-generic-white);

	/** SUCCESS BUTTONS **/
	--success-button-color: var(--color-success-600);
	--success-button-color-disabled: var(--color-success-500);
	--success-button-color-hover: var(--color-success-700);
	--success-button-color-active: var(--color-success-700);

	/** ERROR BUTTONS **/
	--error-button-border: 1px solid var(--color-error-500);
	--error-button-border-hover: var(--color-error-600);
	--error-button-background: inherit;
	--error-button-background-hover: inherit;
	--error-button-background-active: inherit;

	/** TEXT BUTTONS **/
	--text-button-color: var(--color-primary-300);
	--text-button-color-hover: var(--color-primary-700);
	--text-button-color-active: var(--color-primary-800);
	--text-button-color-disabled: var(--color-primary-800);

	/* MODALS */
	--modal-border: var(--border);
	--modal-border-radius: 16px;
	--modal-background-color: var(--color-neutral-100);

	/* ATTRIBUTES */
	--attribute-background-color: var(--color-neutral-100);
	--attribute-percentage-color: var(--color-neutral-600);
	--attribute-border-radius: 16px;

	/* NOTIFICATIONS - TOASTERS */
	--toast-background-color: var(--color-neutral-200);
	--toast-text-color: var(--color-neutral-700);
	--toast-border-radius: 16px;
	--toast-loader-background: var(--color-neutral-50);

	/* LOADERS */
	--loader-big-part-background: var(--color-neutral-200);
	--loader-small-part-background: #dc30e9;

	/* INPUTS */
	--background-input-field-material: transparent;

	/* ALERT MESSAGE */
	--alert-message-default-background: var(--color-neutral-200);
	--alert-message-default-color: var(--color-neutral-900);
	--alert-message-default-border: none;

	--alert-message-warning-background: #a3742d;
	--alert-message-warning-color: var(--color-neutral-900);
	--alert-message-warning-border: none;

	--alert-message-important-background: var(--color-primary-100);
	--alert-message-important-color: var(--color-neutral-900);
	--alert-message-important-border: none;

	--alert-message-error-background: var(--color-error-900);
	--alert-message-error-color: var(--color-neutral-900);
	--alert-message-error-border: none;

	/* USER BUTTON */
	--user-button-background: var(--color-neutral-200);
	--user-button-background-hover: var(--color-neutral-300);
	--user-button-background-active: var(--color-neutral-400);

	/* USER REGISTRATION INFOS */
	--user-registration-infos-background: var(--color-primary-50);

	/* USERMENU  */
	--user-menu-background: var(--color-generic-background);
	--user-menu-link-background-hover: var(--color-neutral-100);

	/* TAGS */
	--tag-border-radius: 32px;
	--tag-main-color: var(--color-neutral-900);
	--tag-phase-sale-background: var(--color-neutral-100);
	--tag-phase-sale-border: 1px solid var(--color-neutral-200);

	--tag-live-mint-color: var(--tag-main-color);
	--tag-live-mint-background: var(--color-primary-300);

	--tag-eligible-color: var(--tag-main-color);
	--tag-eligible-background: var(--color-success-500);

	--tag-not-eligible-color: var(--tag-main-color);
	--tag-not-eligible-background: var(--color-neutral-400);

	--tag-sold-out-color: var(--tag-main-color);
	--tag-sold-out-background: var(--color-neutral-400);

	--tag-comming-soon-color: var(--tag-main-color);
	--tag-comming-soon-background: var(--color-primary-100);

	/* SELECT */
	--select-filter-background: var(--color-neutral-200);
	--select-filter-item-background: var(--color-neutral-300);

	/* FILTER */
	--filter-tags-background-color: var(--color-neutral-300);
	--filter-tags-font-weight: 400;

	/* HEADER BANNER */
	--header-background: var(--color-generic-background);
	--header-link-color-hover: var(--color-neutral-900);
	--header-link-color: var(--color-neutral-500);
	--registration-banner-background: var(--color-primary-50);

	/* FOOTER */
	--footer-link-color: var(--color-neutral-500);
	--footer-background: var(--color-generic-background);

	/* NFT CARDS */
	--nft-card-text-color: var(--color-neutral-600);
	--nft-card-footer-background: var(--color-neutral-100);

	--border-radius-nft: 16px 16px 0 0;
	--nft-card-info-border-radius: 0 0 16px 16px;
	--border-radius-nft-container: 16px;

	/* COLLECTION CARD */
	--collection-card-border: "none";
	--collection-card-border-radius: 16px;
	--collection-card-img-border: none;
	--collection-card-img-border-radius: 8px;
	--collection-card-font-color: var(--color-neutral-900);
	--collection-card-background-filter: blur(8px);

	/* MARKET PREVIEW */
	--border-market-preview: none;
	--border-radius-market-preview: 16px;
	--box-shadow-market-preview: none;
	--background-color-market-preview: rgba(17, 24, 39, 0.3);
	--background-color-mint-plus-minus-button: rgba(17, 24, 39, 0.3);
	--background-color-mint-plus-minus-value: rgba(17, 24, 39, 0.3);
	--background-color-market-preview-hover: rgba(17, 24, 39, 0.5);
	--owner-typo-color: var(--color-neutral-900);
	--color-input-minus-button: var(--color-neutral-900);

	/* CHIPS */
	--chip-with-number-background-color-selected: var(--color-neutral-50);
	--chip-with-number-border: 1px solid var(--color-neutral-50);
	--chip-number-circle-background: var(--user-button-background);
	--selected-text-chip-color: var(--color-neutral-900);
	--not-selected-text-chip-color: var(--color-neutral-500);
}

[theme-mode="dark"] {
	--color-neutral-50: #120819;
	--color-neutral-100: #2d1f37;
	--color-neutral-200: #463751;
	--color-neutral-300: #594b63;
	--color-neutral-400: #776b80;
	--color-neutral-500: #a79caf;
	--color-neutral-600: #d7d1db;
	--color-neutral-700: #e9e5eb;
	--color-neutral-800: #f5f3f6;
	--color-neutral-900: #faf9fb;

	--color-primary-50: #2b185d;
	--color-primary-100: #47288a;
	--color-primary-200: #552fa8;
	--color-primary-300: #5f34bc;
	--color-primary-400: #886be7;
	--color-primary-500: #a596ef;
	--color-primary-600: #c4bcf6;
	--color-primary-700: #dddafa;
	--color-primary-800: #edebfc;
	--color-primary-900: #f5f4fe;
}
